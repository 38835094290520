/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

/* Font */
@font-face {
  font-family: 'M PLUS Rounded 1c';
  font-weight: 100;
  font-style: normal;
  src: url('./assets/fonts/MPLUSRounded1c-Thin.ttf') format("truetype");
}
@font-face {
  font-family: 'M PLUS Rounded 1c';
  font-weight: 300;
  font-style: normal;
  src: url('./assets/fonts/MPLUSRounded1c-Light.ttf') format("truetype");
}
@font-face {
  font-family: 'M PLUS Rounded 1c';
  font-weight: normal;
  font-style: normal;
  src: url('./assets/fonts/MPLUSRounded1c-Regular.ttf') format("truetype");
}
@font-face {
  font-family: 'M PLUS Rounded 1c';
  font-weight: 500;
  font-style: normal;
  src: url('./assets/fonts/MPLUSRounded1c-Medium.ttf') format("truetype");
}
@font-face {
  font-family: 'M PLUS Rounded 1c';
  font-weight: bold;
  font-style: normal;
  src: url('./assets/fonts/MPLUSRounded1c-Bold.ttf') format("truetype");
}
@font-face {
  font-family: 'M PLUS Rounded 1c';
  font-weight: 800;
  font-style: normal;
  src: url('./assets/fonts/MPLUSRounded1c-ExtraBold.ttf') format("truetype");
}
@font-face {
  font-family: 'M PLUS Rounded 1c';
  font-weight: 900;
  font-style: normal;
  src: url('./assets/fonts/MPLUSRounded1c-Black.ttf') format("truetype");
}

html {
  color: var(--app-text-color);
}

body {
  --ion-text-color: #393d40;
  --ion-text-color-rgb: 57, 61, 64;
}

ion-content {
  --ion-background-color: var(--app-background-color);
  --ion-text-color: #393d40;
  --ion-text-color-rgb: 57, 61, 64;
}

ion-content.md {
  ion-list {
    padding-top: 0;
  }
}
